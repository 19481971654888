import { Component, HostListener, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
//import { SplashScreen } from '@capacitor/splash-screen';

// Import the functions you need from the Firebase SDKs you need
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics } from 'firebase/analytics';
import 'firebase/messaging';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/mastermodule/service/notifications.service';
import { ServiceService } from 'src/app/mastermodule/service/service.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  frbs_app: FirebaseApp;
  frbs_analytics: Analytics;

  constructor(
    public platform: Platform,
    public api: ServiceService // , private notificationsService: NotificationsService
  ) {}

  async ngOnInit() {
    // TODO: uncomment to Initialize Firebase
    // Add "src/firebase-messaging-sw.js" to /ionic/postulate_erp/angular.json under assets arrays (projects.app.architect.build.options.assets, projects.app.architect.test.options.assets)
    // this.frbs_app = initializeApp(environment.firebaseConfig);
    // this.frbs_analytics = getAnalytics(this.frbs_app);
    // await this.notificationsService.init();
  }

  ngAfterViewInit() {
    this.platform.ready().then(async () => {
      // window.addEventListener('visibilitychange', function (evt) {
      //   if (document.visibilityState === 'hidden') {
      //     this.unloadNotification(evt);
      //   }
      // });
      // await SplashScreen.hide();
      // TODO: Uncomment for using Firebase
      // await this.notificationsService.requestPermission();
    });
  }

  // Add beforeunload event listener
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    // if (this.isClosingBrowserOrAllTabs()) {
    //   this.onAppExit($event);
    // }
  }

  // to check if the browser or all tabs are closing
  isClosingBrowserOrAllTabs(): boolean {
    // Check if there is a specific session storage key
    const closingKey = sessionStorage.getItem('closingKey');
    // Set the closing key to identify the unload event
    sessionStorage.setItem('closingKey', 'true');

    // Check if the key was already present
    if (closingKey) {
      // If the key is present, it means a new tab is opening or the user is navigating away
      return false;
    }

    // If the key was not present, it means this is the first unload event
    return true;
  }

  // Handler for beforeunload event
  onAppExit(event) {
    const url = `${environment.API_URL}mastermodule/logout`;

    const data = {
      user_id: this.api.localStorage.getItem('user_id'),
      login_dt: this.api.localStorage.getItem('login_dt'),
      sid: this.api.get_session_id_login(),
      test: true,
    };

    if (navigator.sendBeacon) {
      const blob = new Blob([JSON.stringify(data)], {
        type: 'application/json; charset=UTF-8',
      });
      this.api.clearUserSession();
      // Here we can send a synchronous request, which is discouraged but can be done for session cleanup
      navigator.sendBeacon(url, blob);
    } else {
      // Fallback for browsers that do not support sendBeacon
      const headers = {
        'Content-Type': 'application/json',
        // 'Authorization': this.api.get_session_id_login()
      };
      this.api.clearUserSession();
      fetch(url, {
        keepalive: true,
        credentials: 'include',
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      });
    }
  }
}
